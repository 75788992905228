<template>
<div
    role="button" tabindex="0"
    class="menu-container" :class="{ 'open-text': isOpen && !iconOnly }"
    :aria="text" v-on:keyup.enter="toggle"
>
    <div class="menu-label">
        <div
            v-if="iconOnly" ref="tiBtn"
            role="button" tabindex="0" class="icon icon-padding ti-btn"
            :class="{ 'open-icon': isOpen }"
            v-on:click="toggle" v-on:keydown.enter="toggle"
        >
            <span class="icon" :class="faIcon" />
        </div>
        <div
            v-else-if="menuType === 'user'" role="button"
            tabindex="0" :class="{ 'open-icon': isOpen }" v-on:click="toggle"
            v-on:keydown.enter="toggle"
        >
            <img id="profile-icon" class="profile-icon" :src="defaultProfileImgSrc" alt="user account menu">
        </div>
        <div
            v-else role="button"
            tabindex="0" class="selected paragraph regular" :class="{ 'open-icon': isOpen, 'duo-icon': duoToneIcon }"
            v-on:click="toggle" v-on:keydown.enter="toggle"
        >
            {{ text }}
            <span class="option-icon" :class="faIcon" />
        </div>
    </div>
    <Transition name="fade">
        <div v-if="isOpen" class="tray-menu-background" />
    </Transition>
    <Transition name="slide">
        <div v-if="isOpen" ref="trayMenu" class="tray-menu">
            <div class="close-container">
                <div
                    id="cart-close" class="close" :aria="$t('WIDGETS.CLOSE')"
                    tabIndex="0" role="button"
                    v-on:click="close" v-on:keydown.enter="close"
                >
                    <i class="fa-regular fa-xmark" />
                </div>
            </div>
            <div
                v-for="(option, i) of options" :id="`nav-menu-item-${i}-${option.value}`" :key="i"
                :class="{ 'top-item': i === 0}"
                class="nav-menu-item item paragraph-l"
            >
                <div>
                    <div v-if="option.secondaryMenuOptions">
                        <AccordionMenu :option="option" />
                    </div>
                    <div
                        v-else role="button"
                        tabindex="0" :aria="option.text"
                        v-on:click="handleChange(option)" v-on:keydown.enter="handleChange(option)"
                    >
                        <span v-if="!!option.icon" class="option-icon" :class="faOptionIcon(option)" />
                        {{ option.text }}
                    </div>
                </div>

                <div v-if="showSigninButton">
                    <div v-if="userAuthenticated" class="nav-menu-item user">
                        <NuxtLink :to="localeRoute({ name: 'userProfile' })" class="account-menu-link">
                            <span class="fa-regular fa-sliders option-icon" />
                            {{ $t('NAVBAR.USER_ACCT_MENU_HEADER') }}
                        </NuxtLink>
                        <div role="button" tabindex="0" v-on:click="logoutUser" v-on:keydown.enter="logoutUser">
                            <span class="fa-regular fa-arrow-right-from-arc option-icon" />
                            {{ $t('NAVBAR.SIGN_OUT') }}
                        </div>
                    </div>
                    <div class="auth-status-container" :class="{authenticated: userAuthenticated}">
                        <div
                            v-if="!userAuthenticated" class="sign-in" tabIndex="0"
                            role="button"
                            :aria="$t('NAVBAR.SIGN_IN')" v-on:click="$emit('login')"
                            v-on:keydown.enter="$emit('login')"
                        >
                            <div class="text">{{ $t('NAVBAR.SIGN_IN') }}</div>
                            <span class="fa-regular fa-arrow-right-to-arc sign-in-icon" />
                        </div>
                        <div v-else-if="userAuthenticated && currentUser" class="logged-on">
                            <img id="profile-icon" class="profile-icon" src="/images/play/mayo.svg" alt="">
                            <div>
                                <div class="label">{{ $t('NAVBAR.LOGGED_ON') }}:</div>
                                <div class="username">{{ currentUser.username }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</div>
</template>

<script lang="ts">
import { type PropType } from 'vue'
import { CurrentUser } from '$services/user'
import type { NavMenuOption, menuType } from './NavigationBar.vue'
import AccordionMenu from '$components/navigation/AccordionMenu.vue'

export default defineNuxtComponent({
    components: { AccordionMenu },
    props: {
        text: String,
        icon: String,
        duoToneIcon: Boolean,
        iconOnly: Boolean,
        options: {
            type: Array as PropType<NavMenuOption[]>,
            required: false
        },
        menuType: {
            type: String as PropType<menuType>,
            required: true
        },
        userAuthenticated: {
            type: Boolean,
            required: false
        },
        currentUser: {
            type: Object as PropType<typeof CurrentUser>
        },
        showSigninButton: {
            type: Boolean,
            required: false
        }
    },

    emits: ['menuSelect', 'open', 'close', 'logout', 'login'],

    data() {
        return {
            isOpen: false
        }
    },

    computed: {
        faIcon(): string {
            let iconClasses = ''
            if (this.duoToneIcon) {
                // fa-duotone
                iconClasses += 'fa-duotone'
            } else {
                // fa-solid
                iconClasses += 'fa-solid'
            }

            if (this.icon) {
                iconClasses = `${iconClasses} ${this.icon}`
            }

            return iconClasses
        },

        // we could load this as an inline svg instead and style the fill
        defaultProfileImgSrc() {
            if (this.isOpen) {
                return '/images/play/mayo_hover.svg'
            }
            return '/images/play/mayo.svg'
        }
    },

    watch: {
        $route() {
            // close any open menus on route
            this.isOpen = false
        }
    },

    beforeUnmount() {
        document.removeEventListener('click', this.onDocumentClick)
        document.removeEventListener('keyup', this.onEscPress)
    },

    methods: {
        close() {
            this.$emit('close')
            this.isOpen = false

            // set zendesk widget's z-index back to what it was.
            const zendeskWidget = document.getElementById('launcher')
            if (zendeskWidget) {
                zendeskWidget.style.zIndex = '999999'
            }

            document.removeEventListener('click', this.onDocumentClick)
            document.removeEventListener('keyup', this.onEscPress)
        },

        faOptionIcon(option: NavMenuOption): string {
            if (option.icon) {
                return `fa-solid ${option.icon}`
            }

            return ''
        },

        handleChange(option: NavMenuOption) {
            this.$emit('menuSelect', option.value)
            this.close()
        },

        logoutUser() {
            this.$emit('logout')
        },

        // Close when clicking anywhere outside the open menu
        onDocumentClick(event: Event) {
            if (!this.isOpen) return
            const clickedEl = event.target as HTMLElement
            const tray = this.$refs.trayMenu as HTMLElement
            if (clickedEl === tray || tray.contains(clickedEl)) return
            this.close()
        },

        // Close when pressing the escape key
        onEscPress(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                if (!this.isOpen) return
                this.close()
            }
        },

        open() {
            this.$emit('open')
            this.isOpen = true

            // set zendesk widget's z-index lower than this tray.
            const zendeskWidget = document.getElementById('launcher')
            if (zendeskWidget) {
                zendeskWidget.style.zIndex = '1'
            }

            setTimeout(() => {
                document.addEventListener('click', this.onDocumentClick)
                document.addEventListener('keyup', this.onEscPress)
            }, 50)
        },

        toggle() {
            this.isOpen ? this.close() : this.open()
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.menu-container {
    color: var(--neutral-50);

    .menu-label {
        display: inline-block;
        margin: 4px 0;
        line-height: 0;
        margin: 4px 0;
        vertical-align: middle;
    }

    .tray-menu {
        background: var(--background-mobile-nav-tray);
        border-radius: 0 48px 0 0;
        height: 100%;
        padding: 42px 1px 0px 24px;
        position: fixed;
        left: 0;
        top: 0;
        overflow-y: auto; // let the menu scroll when vh is too short
        width: 302px;
        z-index: 200; // above faded background

        // Custom scrollbar
        /* Firefox  */
        scrollbar-color: var(--primary-200) transparent;
        /* Chrome, Edge and Safari */
        *::-webkit-scrollbar {
            width: 10px;
        }
        *::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: transparent;
        }
        *::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: var(--primary-200);
        }

        .close-container {
            cursor: pointer;
            position: absolute;
            right: 24px;
            top: 30px;

            svg {
                width: 36px;
                height: 36px;
            }
        }

        .nav-menu-item {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            color: var(--neutral-200);
            font-size: 20px;
            font-weight: 700;
            gap: 16px;
            letter-spacing: 0.6px;
            padding: 12px 0px;
            text-transform: uppercase;

            .account-menu-link{
                color: var(--neutral-200);
            }

            .option-icon {
                padding-right: 8px;
                margin: auto 0;
                width: 16px;
            }

            &.top-item {
                margin-top: 0;
            }

            // add some space b/w primary and secondary menu items
            &.primary-list-end {
                margin-bottom: 16px;
            }

            &.user {
                display: flex;
                flex-direction: column;
                gap: 24px;
                color: var(--neutral-200);
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                padding: 32px 0 0 0;
            }
        }

        .auth-status-container {
            background: #4C4E57;
            border-radius: 32px 0px 0px 0px;
            padding: 32px 24px;
            position: absolute;
            bottom: 0;
            width: 100%;
            margin-left: -24px;

            &.authenticated {
                padding: 20px 42px 20px 24px;
            }

            .sign-in {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .text {
                    color: var(--neutral-50);
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 20px;
                    text-transform: uppercase;
                }

                .sign-in-icon {
                    width: 24px;
                    height: 24px;
                    color: var(--neutral-200);
                }
            }

            .logged-on {
                display: flex;
                flex-direction: row;
                gap: 11px;
                align-items: center;

                .label {
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 12px;
                    text-transform: uppercase;
                    padding-bottom: 4px;
                }

                .username {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 16px;
                }
            }
        }
    }

    .nav-menu-background {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 199;
        background-color: rgba(0, 0, 0, 0.80);
    }

    &.icon-nav-link {
        .menu-label:hover {
            border-radius: unset;
            background-color: unset;
            color: var(--primary-200);
        }
    }

    .icon {
        cursor: pointer;
        height: 26px;
        width: 28px;

        :hover {
            background-color: transparent;
            color: var(--primary-200)
        }
    }

    .profile-icon {
        height: 44px;
        width: 44px;
    }

    .ti-btn {
        outline: 0;
        overflow: hidden;
        position: relative;
        user-select: none;
        transition: box-shadow 150ms ease-out;
    }
}

.tray-menu-background {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 199;
    background-color: rgba(0, 0, 0, 0.80);
}

// tray menu background fade in
.fade-enter-active {
    transition: opacity 0.3s ease;
}

.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

// tray-menu transtions
.slide-enter-active {
    transition: all 0.4s ease-out;
}

.slide-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(-302px);
}
</style>
