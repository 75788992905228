<template>
<div
    class="accordion-menu-item" :class="{ active: isOpen }" role="button"
    tabindex="0" v-on:click="toggle" v-on:keydown.enter="toggle"
>
    <div>{{ option.text }}</div>
    <div class="toggle" :class="{ rotated: isOpen }">
        <span class="accordion-icon fa-solid fa-caret-right" />
    </div>
</div>
<Transition>
    <div v-if="isOpen" class="open-submenu">
        <div
            v-for="(secondaryOption, i) of option.secondaryMenuOptions" :key="i" class="accordion-menu"
            tabIndex="0"
            :aria="option.text"
        >
            <div
                class="accordion-submenu-item semibold" role="button" tabIndex="0"
                :aria="secondaryOption.text"
                v-on:click="select(secondaryOption)" v-on:keydown.enter="select(secondaryOption)"
            >
                {{ secondaryOption.text }}
            </div>
        </div>
    </div>
</Transition>
</template>

<script lang="ts">
import { type PropType } from 'vue'
import type { NavMenuOption } from './NavigationBar.vue'

export default defineNuxtComponent({
    props: {
        option: {
            type: Object as PropType<NavMenuOption>,
            required: true
        }
    },
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        close() {
            this.isOpen = false
        },
        open() {
            this.isOpen = true
        },
        select(secondaryOption: NavMenuOption) {
            let route
            switch (secondaryOption.value) {
            case 'Games':
                route = { name: 'shop-tab', params: { tab: 'games' } }
                break
            case 'Merch':
                route = { name: 'shop-tab', params: { tab: 'merch' } }
                break
            case 'Giftcards':
                route = { name: 'shop-tab', params: { tab: 'giftcards' } }
                break
            default:
                route = { name: secondaryOption.value }
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            void this.$router.push(this.localeRoute(route))
        },
        toggle() {
            this.isOpen ? this.close() : this.open()
        }
    }
})
</script>

<style lang="scss" scoped>
.accordion-menu-item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    transition-duration: 0.1s;
    &.active > * {
        color: var(--primary-200);
    }
    .toggle {
        transition: all 0.3s ease;
        &.rotated {
            transform: rotate(90deg);
        }
    }
}
.accordion-menu {
    .accordion-submenu-item {
        position: relative;
        color: var(--neutral-50);
        font-size: 18px;
        text-transform: none;
    }
}
.open-submenu {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 8px 0px 10px 8px;
}
.v-enter-active,
.v-leave-active {
    transition: all 0.3s ease;
}
.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
