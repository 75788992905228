<template>
<Transition>
    <div v-if="consentRead && !consentSet" class="cookie-consent">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="description" v-html="$t('COOKIE.DESCRIPTION')" />
        <div class="buttons">
            <button class="primary" v-on:click="onAccept">{{ $t('COOKIE.ACCEPT_ALL') }}</button>
            <button class="outline small" v-on:click="onReject">{{ $t('COOKIE.REJECT_ALL') }}</button>
        </div>
    </div>
</Transition>
</template>

<script setup lang="ts">

type ConsentValue = 'granted' | 'denied' | undefined
interface Consent {
    adStorage: ConsentValue,
    adUserData: ConsentValue,
    adPersonalization: ConsentValue,
    analyticsStorage: ConsentValue,
    personalizationStorage: ConsentValue,
    functionalityStorage: ConsentValue,
    securityStorage: ConsentValue
}

const gtm = useGtm()
const consentSet = ref(false)
const consentRead = ref(false)
const consent = ref<Consent>({
    adStorage: undefined,
    adUserData: undefined,
    adPersonalization: undefined,
    analyticsStorage: undefined,
    personalizationStorage: undefined,
    functionalityStorage: undefined,
    securityStorage: undefined
})

const updateConsent = () => {
    gtm.trackEvent({
        event: 'Consent Interaction',
        consent: consent.value
    })
    gtm.trackEvent({
        event: 'Consent Updated'
    })
}

const readConsent = () => {
    const expTimestamp = localStorage.getItem('consent_exp')
    if (expTimestamp) {
        if (Date.now() > Number(expTimestamp)) {
            localStorage.removeItem('consent_exp')
            localStorage.removeItem('consent')
        } else {
            consentSet.value = true
        }
    }
    const consentString = localStorage.getItem('consent')
    if (consentString) {
        consent.value = JSON.parse(consentString)
    } else {
        consentSet.value = false
    }
    consentRead.value = true
}

onMounted(() => {
    if (import.meta.client) {
        readConsent()
    }
    return false
})

const setConsent = (val: Consent) => {
    localStorage.setItem('consent', JSON.stringify(val))
    const expDate = Date.now() + (182 * 24 * 60 * 60 * 1000) // ~6 months in the future
    localStorage.setItem('consent_exp', expDate.toString())
    updateConsent()
    readConsent()
}

const onAccept = () => {
    consent.value.adStorage = 'granted'
    consent.value.adUserData = 'granted'
    consent.value.adPersonalization = 'granted'
    consent.value.analyticsStorage = 'granted'
    setConsent(consent.value)
}

const onReject = () => {
    consent.value.adStorage = 'denied'
    consent.value.adUserData = 'denied'
    consent.value.adPersonalization = 'denied'
    consent.value.analyticsStorage = 'granted'
    setConsent(consent.value)
}

</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.cookie-consent {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: var(--surface-900);
    border: 2px solid var(--primary-300);
    border-radius: 20px;
    z-index: 1000000;
    width: 60%;
    padding: 20px;
    margin: 10px;
    max-width: 500px;

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    @include mq-small-and-less {
        margin: 0px;
        width: 100%;
        max-width: unset;
        border-radius: 20px 20px 0 0;
    }
}

.v-enter-active {
    transition: bottom 0.3s ease-out;
}

.v-leave-active {
    transition: bottom 0.3s ease-in;
}

.v-enter-from,
.v-leave-to {
    bottom: -400px;
}
</style>
