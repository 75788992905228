export namespace Zendesk {

    export interface InitOptions {
        key: string
    }

    class Zendesk {
        private key: string

        constructor(options: Zendesk.InitOptions) {
            this.key = options.key
            this.load()
        }

        private load() {
            const script = document.createElement('script')
            script.setAttribute('id', 'ze-snippet')
            script.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=${this.key}`)
            script.setAttribute('async', 'true')
            script.onload = () => { this.onLoad() }
            document.body.appendChild(script)
        }

        private onLoad() {
            window.zE('messenger', 'show')
        }
    }

    export function install(key: string) {
        const z = new Zendesk({ key })
        return z
    }
}

declare module '@vue/runtime-core' {
}

declare global {
    interface Window {
      zE: (widget: string, cmd: string) => void;
    }
}
