<template>
<Modal v-on:action="handleModalAction" />
<Toast />
<NavigationBar
    :class="{ scrolled: isScrolled }"
/>
<ClientOnly>
    <ShoppingCartTray />
</ClientOnly>
<slot v-on:check-user-auth-status="getUserAuthStatus" />
<Footer />
<CookieConsent />
<Zendesk />
</template>

<script lang="ts">
import { ShoppingCart } from '$services/shopify'
import { CurrentUser } from '$services/user'

// import Modal from '$plugins/modal/Modal.vue'

export default defineNuxtComponent({
    data() {
        return {
            isScrolled: false,
            scrollbarWidth: 'unset'
        }
    },

    head({ _$i18n }) {
        return {
            // TODO: supported language translations for better SEO in those markets.
            meta: [
                {
                    property: 'og:locale',
                    content: 'en_US'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:site_name',
                    content: 'Jackbox Games'
                },
                {
                    name: 'twitter:card',
                    content: 'summary_large_image'
                },
                {
                    name: 'twitter:creator',
                    content: '@jackboxgames'
                },
                {
                    name: 'twitter:site',
                    content: '@jackboxgames'
                },
                {
                    name: 'facebook-domain-verification',
                    content: 'nuuaxgu655yk14irruvpal0s6f6opm'
                },
                {
                    name: 'robots',
                    content: 'max-image-preview:large'
                },
                {
                    name: 'robots',
                    content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
                }
            ]
        }
    },

    mounted() {
        document.addEventListener('scroll', this.onScroll)

        // Set up the Font Awesome icons
        const fontAwesomeScript = document.createElement('script')
        fontAwesomeScript.setAttribute('src', 'https://kit.fontawesome.com/72acec670f.js')
        fontAwesomeScript.setAttribute('crossorigin', 'anonymous')
        document.head.appendChild(fontAwesomeScript)

        // Set up Listrak
        const listrakScript = document.createElement('script')
        listrakScript.setAttribute('src', `https://cdn.listrakbi.com/scripts/script.js?m=${import.meta.env.JBG_LISTRAK_ID}&v=1`)
        document.head.appendChild(listrakScript)

        // user authentication
        void this.refreshUser()
        this.$user.checkLastAuthenticated(CurrentUser.isLoggedIn())
        // Update scrollbar width for shopping cart interactions
        void this.updateScrollbarWidth()
    },

    updated() {
        void this.updateScrollbarWidth()
    },

    created() {
        if (import.meta.client) {
            window.addEventListener('resize', this.updateScrollbarWidth)
        }
    },

    unmounted() {
        if (import.meta.client) {
            window.removeEventListener('resize', this.updateScrollbarWidth)
        }
    },

    computed: {
        cartOpen() {
            return ShoppingCart.isOpen()
        }
    },

    watch: {
        cartOpen() {
            if (this.cartOpen) {
                document.body.classList.add('cart-open')
                document.body.style.paddingRight = this.scrollbarWidth
            } else {
                document.body.classList.remove('cart-open')
                document.body.style.paddingRight = 'unset'
            }
        }
    },

    methods: {
        async refreshUser() {
            await this.$user.loadUserData()
        },
        onScroll() {
            const scroll = window.scrollY

            if (this.isScrolled && scroll < 30) {
                this.isScrolled = false
            } else if (!this.isScrolled && scroll > 80) {
                this.isScrolled = true
            }
        },

        handleModalAction(value: string) {
            if (value === 'logout') {
                void this.$user.logout()
                this.$hideModal()
            }

            if (value === 'updateUserData') {
                void this.$user.loadUserData()
            }
        },

        // can be called from children to trigger authStatus check
        getUserAuthStatus() {
            if (CurrentUser.isDirty) {
                void this.$user.loadUserData()
            }
            this.$user.checkLastAuthenticated(CurrentUser.isLoggedIn())
        },

        updateScrollbarWidth() {
            // A padding-right value equal to the width of the browser's scrollbar is used
            // when the shopping cart is open to prevent the page from "jumping"
            //
            // We await a small timeout to make sure the page is really loaded all the way
            // before computing the width
            setTimeout(() => {
                this.scrollbarWidth = `${window.innerWidth - document.body.clientWidth}px`
            }, 100)
        }
    }
})
</script>

<style lang="scss">
*, *::before, *::after {
    box-sizing: border-box;
}

html {
    background: var(--shade-100);
}

#app {
    display: block;
    color: var(--neutral-50);
    font-family: Inter;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
    margin: 0px;
    width: 100%;
    background: #181c23;
    background: linear-gradient(180deg, var(--surface-800) 7%,#181c23 36%, var(--surface-900) 71%, var(--shade-100) 100%);

    &.no-scroll {
        overflow: hidden;
        height: 100%;
    }

    &.cart-open {
        overflow-y: hidden;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

// grid templates
.grid {
    display: grid;

    &.vertical-center{
        align-items: center;
    }

    &.halves {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-gap: 20px;
    }
}

// Flicker animation used in jnp screens
@keyframes flicker {
    0% { opacity: 1; }

    10% { opacity: 1; }
    12% { opacity: 0.8; }
    14% { opacity: 1; }

    30% { opacity: 1; }
    32% { opacity: 0.8; }
    34% { opacity: 1; }
    36% { opacity: 0.8; }
    38% { opacity: 1; }

    50% { opacity: 1; }
    52% { opacity: 0.8; }
    54% { opacity: 1; }

    60% { opacity: 1; }
    62% { opacity: 0.8; }
    64% { opacity: 1; }

    72% { opacity: 1; }
    74% { opacity: 0.8; }
    76% { opacity: 1; }

    92% { opacity: 1; }
    94% { opacity: 0.8; }
    96% { opacity: 1; }
    98% { opacity: 0.8; }
    100% { opacity: 1; }
}
</style>
